import { useInfiniteQuery } from "@tanstack/react-query";
import tmdbFetch from "backend/tmdb/tmdbFetch";
import CheckInView from "components/CheckInView";
import MovieCard from "features/core/MovieCard";
import MovieGrid from "features/core/MovieGrid";
import ChangeMovieCardSize from "features/home/components/ChangeMovieCardSize";
import SidebarMovies from "features/home/components/SidebarMovies";
import { useDebounce } from "hooks/useDebounce";
import Header from "layouts/Header";
import { useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { TMDBMovieList, TMDBMovieResult } from "types/tmdb";
import { getUniqueListBy } from "utils/helper";

const HomePage = () => {
  const [keyword, setKeyword] = useState("");
  const debouncedKeyword = useDebounce(keyword, 500);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [sortBy, setSortBy] = useState("popular");
  const {
    isLoading,
    fetchNextPage,
    hasNextPage,
    data: dataMovies = null,
  } = useInfiniteQuery({
    queryKey: ["Movies", sortBy, selectedCategory, selectedYear, debouncedKeyword],
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const data: TMDBMovieList = await tmdbFetch(
          debouncedKeyword
            ? `/search/movie`
            : `/${
                selectedCategory || selectedYear ? "discover/movie" : `movie/${sortBy}`
              }?region=US`,
          {
            parseResponse: JSON.parse,
            params: {
              page: pageParam,
              query: debouncedKeyword,
              primary_release_year: selectedYear,
              with_genres: selectedCategory,
            },
          }
        );
        return data;
      } catch (error) {
        return null;
      }
    },
    keepPreviousData: true,
    getPreviousPageParam: (firstPage) => (firstPage ? firstPage.page + 1 : undefined),
    getNextPageParam: (lastPage) => (lastPage ? lastPage.page + 1 : undefined),
    staleTime: 10 * 60 * 1000,
    cacheTime: 15 * (60 * 1000),
  });
  const moviesList: TMDBMovieResult[] = useMemo(() => {
    // Flatten the nested arrays using `flatMap` instead of `map` and `flat`
    return getUniqueListBy(
      (dataMovies?.pages?.flatMap((page) => page?.results) as TMDBMovieResult[]) || [],
      "id"
    );
  }, [dataMovies?.pages]);
  if (isLoading) return null;
  return (
    <>
      <Helmet>
        <title>Popcorn Time Online</title>
        <meta
          name="description"
          content="Popcorn Time Online No more downloads hassle! The best movies &amp; TV shows, for free! Directly on Popcorn Time Online in HD + subtitles."
        />
      </Helmet>
      <Header keyword={keyword} setKeyword={setKeyword} />
      <SidebarMovies
        setKeyword={setKeyword}
        setSelectedCategory={setSelectedCategory}
        setSortBy={setSortBy}
        setSelectedYear={setSelectedYear}
        selectedCategory={selectedCategory}
      />
      <MovieGrid style={{ marginTop: isMobile ? "90px" : "70px" }}>
        {moviesList.map((movie) => (
          <MovieCard key={movie.id} movie={movie} />
        ))}
      </MovieGrid>
      {hasNextPage && Boolean(dataMovies?.pages.slice(-1)?.[0]?.results.length) && (
        <CheckInView onInView={fetchNextPage} className="flex justify-center pt-2 mt-5" />
      )}
      <ChangeMovieCardSize />
    </>
  );
};

export default HomePage;
