import { imageTMDB } from "backend/tmdb/image";
import IconHalfStar from "components/Icons/IconHalfStar";
import IconStar from "components/Icons/IconStar";
import IconStarEmpty from "components/Icons/IconStarEmpty";
import LazyImage from "components/LazyImage";
import ButtonAddFavorite from "features/details/ButtonAddFavorite";
import { Link } from "react-router-dom";
import { TMDBMovieResult } from "types/tmdb";

const Rating = ({ value }: { value: number }) => {
  const stars = Array(5)
    .fill(0)
    .map((item, index) => <IconStarEmpty key={index} />);
  let i;
  for (i = 0; i < value; i++) {
    // this will loop Math.floor(value) times
    stars[i] = <IconStar key={i} />;
  }
  if (value % 1 !== 0)
    // if value is a decimal, add a half star
    stars[i - 1] = <IconHalfStar key={i - 1} />;
  return (
    <div className="stars" style={{ fontSize: "34.68px" }}>
      {stars}
    </div>
  );
};

const MovieCard = ({ movie }: { movie: TMDBMovieResult }) => {
  return (
    <Link
      to={`/details/movie?tmdb_id=${movie.id}`}
      style={{
        transitionDelay: "0s, 0.0434783s",
        opacity: 1,
        transform: "scale(1, 1)",
      }}
      className="movie p1"
    >
      <LazyImage src={`https://image.tmdb.org/t/p/w300${movie.poster_path}`} alt={movie.title} />
      <div className="poster_slide">
        <div className="poster_slide_cont">
          <div className="poster_slide_bg" />
          <div className="poster_slide_details">
            <div className="title">
              {movie.title || movie.original_title}
              <div className="year">
                <ButtonAddFavorite
                  tmdbId={movie.id.toString()}
                  poster={imageTMDB.image300(movie.poster_path)}
                  title={movie.title || movie.original_title}
                  year={new Date(movie.release_date).getFullYear()}
                  score={movie.vote_average}
                />{" "}
                {new Date(movie.release_date).getFullYear()}
              </div>
            </div>
            <div className="details">
              <div className="tools" style={{ display: "none", fontSize: "24.48px" }}>
                <span className="icon2 heart fav-btn " />{" "}
                <ButtonAddFavorite
                  tmdbId={movie.id.toString()}
                  poster={imageTMDB.image300(movie.poster_path)}
                  title={movie.title || movie.original_title}
                  year={new Date(movie.release_date).getFullYear()}
                  score={movie.vote_average}
                />{" "}
                &nbsp;
                <span className="icon2 download" />
              </div>
              <Rating value={movie.vote_average / 2} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default MovieCard;
