import { LocalStorage } from "constants/localStorage";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { FavoriteCardType } from "types";
import classNames from "utils/classNames";

const ButtonAddFavorite = (props: {
  tmdbId: string;
  poster: string;
  title: string;
  year: number;
  score: number;
}) => {
  const followLocalStorage: FavoriteCardType[] = JSON.parse(
    localStorage.getItem(LocalStorage.favoriteList) || "[]"
  );
  const location = useLocation();
  const movieAdded = {
    tmdbId: props.tmdbId,
    poster: props.poster,
    title: props.title,
    year: props.year,
    score: props.score,
    mediaType: location.pathname.split("/").pop(),
  };
  const [hasFollowed, setHasFollowed] = useState(
    followLocalStorage.some((movie) => movie.tmdbId === movieAdded.tmdbId)
  );
  const handleToggleFollowMovie = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (hasFollowed) {
      const removedHistory = followLocalStorage.filter((item) => item.tmdbId !== movieAdded.tmdbId);
      localStorage.setItem(LocalStorage.favoriteList, JSON.stringify(removedHistory));
      setHasFollowed((prev) => !prev);
      return;
    }
    localStorage.setItem(
      LocalStorage.favoriteList,
      JSON.stringify([movieAdded, ...followLocalStorage])
    );
    setHasFollowed((prev) => !prev);
  };
  return (
    <span
      className={classNames(
        isMobile ? "movie-menu__add-favorite js-add-favorite" : "tool icon2 heart fav-btn",
        hasFollowed ? "infavs" : ""
      )}
      onClick={handleToggleFollowMovie}
    />
  );
};

export default ButtonAddFavorite;
