export const TMDB_API_KEY = "95f2419536f533cdaa1dadf83c606027";
export const APP_DOMAIN = "https://teuflix.pages.dev";
export const APP_PROXIES = [
  "https://worker-patient-bush-6543.womova3447.workers.dev",
  "https://worker-small-boat-74e2.yeyamid927.workers.dev",
  "https://worker-noisy-union-0665.xivif77487.workers.dev",
  "https://worker-hidden-river-b606.ragoten119.workers.dev",
  "https://worker-nameless-sun-2c1a.sesih44225.workers.dev",
  "https://worker-mute-mouse-d9ee.yibef96288.workers.dev",
];
export const APP_OPENSUBTITLES_API = "https://opensubtitles.soneme7359.workers.dev/?destination=";
